import React from "react";
import { iframeResize } from "iframe-resizer";
import { iframeResizer } from "iframe-resizer";

function ResultPageWizardDogs() {
  return (
    <section class="containerResultPageWizard">
      <section className="sectionFixedCtaBuy one">
        <div className="dataDiscount">
          <div>
            <span class="quantity">- 25%</span>
          </div>

          <div>
            <p>45,47€</p>
            <p>34,10€ </p>
          </div>
        </div>
        <button className="btn calc">
          <a href="https://wildbalance.es/cart/45018279346477:1" target="_top">
            Comprar
          </a>
        </button>
      </section>

      <section class="explainStarterPack">
        <div class="bannerStarterPack">
          <h3>Pack Iniciación al BARF para Perros con alma de Lobo</h3>

          <div class="imgStarterPackDog">
            <img
              src="https://wildbalance.es/cdn/shop/products/wild-balance_pack-iniciacion-barf-perros.png?v=1681896742&width=800"
              alt="packIniciacion"
            />
          </div>
        </div>

        <div class="containerPacksElements">
          <h3>¿Qué incluye el pack?</h3>

          <div class="elementsPack">
            <article>
              <div>
                <img
                  src="https://wildbalance.es/cdn/shop/products/wild-balance_menu-barf-pollo-perros-min.png?v=1681890658&width=900"
                  alt="packPollo"
                />
              </div>

              <div>
                <h4>Menú 1kg de Iniciación de Pollo</h4>
                <p class="included">Incluido</p>
                <p>Lo mejor para empezar con pollo, calabaza y zanahoria</p>
              </div>
            </article>

            <article>
              <div>
                <img
                  src="https://wildbalance.es/cdn/shop/products/wild-balance_menu-barf-pollo-perros-min.png?v=1681890658&width=900"
                  alt="packPollo"
                />
              </div>

              <div>
                <h4>Menú 2,5kg de Pollo</h4>
                <p class="included">Incluido</p>
                <p>
                  El Menú BARF de pollo para perros es monoproteico y muy
                  completo. Además, el pollo es una proteína baja en grasas y,
                  ¡no puede faltar en su alimentación mensual!
                </p>
              </div>
            </article>

            <article>
              <div>
                <img
                  src="https://wildbalance.es/cdn/shop/products/wild-balance_menu-barf-pavo-perros-min.png?v=1681890605&width=900"
                  alt="packPavo"
                />
              </div>

              <div>
                <h4>Menú 2,5kg de Pavo</h4>
                <p class="included">Incluido</p>
                <p>
                  La carne de pavo es muy baja en grasas, por lo que esta
                  proteína es muy buena opción para iniciarse en la alimentación
                  natural.{" "}
                </p>
              </div>
            </article>

            <article>
              <div>
                <img
                  src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/WiB_perro-gafitas.jpg?v=1701774543"
                  alt=""
                />
              </div>

              <div>
                <h4>Asesoramiento nutricional gratuito</h4>

                <p class="included">Incluido</p>
                <p>
                  Incluye guía para realizar una correcta transición a la dieta
                  BARF + Guía para calcular la ración diaria que debe comer tu
                  perro
                </p>
              </div>
            </article>
          </div>
        </div>
      </section>

      <section className="sectionFixedCtaBuy">
        <div className="dataDiscount">
          <div>
            <span class="quantity">- 25%</span>
          </div>

          <div>
            <p>45,47€</p>
            <p>34,10€ </p>
          </div>
        </div>
        <button className="btn calc">
          <a href="https://wildbalance.es/cart/45018279346477:1" target="_top">
            Comprar
          </a>
        </button>
      </section>

      <section class="accordionDetails">
        <p>
          <button
            class="btn btn-primary faq-question"
            type="button"
            data-toggle="collapse"
            data-target="#collapse1"
            aria-expanded="false"
            aria-controls="collapse1"
          >
            Ingredientes
          </button>

          <div class="collapse" id="collapse1">
            <div class="card card-body">
              <p>
                Alimento de Pollo con Calabaza y Zanahoria para perros:
                <p>
                  Pollo 80% (Hueso carnoso: alita, carcasa, cuello. Carne de
                  músculo: pechuga, corazón y molleja. Vísceras: hígado),
                  Verduras 20% (calabaza y zanahoria)
                </p>
              </p>

              <section class="ingredientesImg">
                <div class="cajaIngrediente">
                  <div class="aporteImg">
                    <img
                      class="turkeyOrChicken"
                      src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/meat_4.png?v=1694512102"
                      alt=""
                    />
                  </div>

                  <div class="porcentajes">
                    <p>
                      <p>80%</p>
                      <span> Pollo</span>
                    </p>
                  </div>
                </div>

                <div class="cajaIngrediente">
                  <div class="aporteImg">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/verdura.svg?v=1690370422"
                      alt=""
                    ></img>
                  </div>

                  <div class="porcentajes">
                    <p>
                      <p>20%</p>
                      <span> Calabaza y zanahoria</span>
                    </p>
                  </div>
                </div>
              </section>

              <p>
                Alimento Completo de Pollo con Verduras Frescas para perros:
                <p>
                  Pollo 80% (Hueso carnoso: alita, carcasa, cuello. Carne de
                  músculo: pechuga, corazón y molleja. Vísceras: hígado),
                  Verduras y frutas 20% (zanahoria, calabaza, calabacín,
                  remolacha, manzana, pera, pimiento rojo, plátano, acelgas,
                  perejil), semillas de cía, espirulina, semillas de cáñamo,
                  romero, ascophyllum nodosum, perejil y alfalfa.
                </p>
              </p>

              <section class="ingredientesImg">
                <div class="cajaIngrediente">
                  <div class="aporteImg">
                    <img
                      class="turkeyOrChicken"
                      src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/meat_4.png?v=1694512102"
                      alt=""
                    />
                  </div>

                  <div class="porcentajes">
                    <p>
                      <p>80%</p>
                      <span> Pollo</span>
                    </p>
                  </div>
                </div>

                <div class="cajaIngrediente">
                  <div class="aporteImg">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/fruta.svg?v=1690370409"
                      alt=""
                    ></img>
                  </div>

                  <div class="porcentajes">
                    <p>
                      <p>20%</p>
                      <span> Verduras y frutas</span>
                    </p>
                  </div>
                </div>
              </section>

              <p>
                Alimento Completo de Pavo con Verduras Frescas para perros:
                <p>
                  Pavo 80% (Hueso carnoso: carcasa y cuello. Carne de músculo:
                  pechuga, corazón y molleja. Vísceras: hígado), Verduras y
                  frutas 20% (zanahoria, calabaza, calabacín, remolacha,
                  manzana, pera, pimiento rojo, plátano, acelgas, perejil),
                  semillas de cía, espirulina, semillas de cáñamo, romero,
                  ascophyllum nodosum, perejil y alfalfa.
                </p>
              </p>

              <section class="ingredientesImg">
                <div class="cajaIngrediente">
                  <div class="aporteImg">
                    <img
                      class="turkeyOrChicken"
                      src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/meat_4.png?v=1694512102"
                      alt=""
                    />
                  </div>

                  <div class="porcentajes">
                    <p>
                      <p>80%</p>
                      <span> Pavo</span>
                    </p>
                  </div>
                </div>

                <div class="cajaIngrediente">
                  <div class="aporteImg">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/fruta.svg?v=1690370409"
                      alt=""
                    ></img>
                  </div>

                  <div class="porcentajes">
                    <p>
                      <p>20%</p>
                      <span> Verduras y frutas</span>
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </p>

        <p>
          <button
            class="btn btn-primary faq-question"
            type="button"
            data-toggle="collapse"
            data-target="#collapse2"
            aria-expanded="false"
            aria-controls="collapse2"
          >
            Información nutricional
          </button>

          <div class="collapse" id="collapse2">
            <div class="card card-body">
              <p>
                <b>Alimento de Pollo con Calabaza y Zanahoria para perros:</b>
                Proteína 11,4% - Grasa bruta 5,6% - Humedad 75% - Minerales 3,5%
                - Hidratos de carbono 4,5% - Fibra bruta 0,5%
              </p>

              <p>
                <b>
                  Alimento Completo de Pollo con Verduras Frescas para perros:
                </b>
                Proteína 13,7% - Grasa bruta 6% - Humedad 73,2% - Minerales 2,7%
                - Hidratos de carbono 4,4% - Fibra bruta 0,5%
              </p>

              <p>
                <b>
                  Alimento Completo de Pavo con Verduras Frescas para perros:
                </b>
                Proteína 16,3% - Grasa bruta 11,5% - Humedad 69% - Minerales
                2,6% - Hidratos de carbono 0,6% - Fibra bruta 0,6%
              </p>
            </div>
          </div>
        </p>

        <p>
          <button
            class="btn btn-primary faq-question"
            type="button"
            data-toggle="collapse"
            data-target="#collapse3"
            aria-expanded="false"
            aria-controls="collapse3"
          >
            Descripción
          </button>

          <div class="collapse" id="collapse3">
            <div class="card card-body">
              <p>
                <b>
                  <p>Pack Dieta BARF Iniciación para Perros</p>
                </b>
                Si ya te has decidido y quieres alimentar a tu peludo con comida
                natural, ¡enhorabuena! ahora ya lo único que necesitas es
                nuestro pack de dieta BARF de iniciación para perros. Incluye{" "}
                <b>
                  1kg del menú de iniciación, 2,5kg del menú completo de pollo
                </b>{" "}
                con verduras frescas y <b>2,5kg del menú completo de pavo</b>{" "}
                con verduras frescas. Todas las proteínas que componen el{" "}
                <b>pack de iniciación al BARF</b> para perros son muy ligeras,
                perfectas para que el organismo se adapte a la nueva comida y
                tengan buenas digestiones los primeros días de la transición.
              </p>

              <p>
                Los menús de Wild Balance están elaborados con{" "}
                <b>ingredientes 100% naturales</b> y frescos, directos de
                granjas y huertos locales, para que tu perro se alimente de la
                forma más natural posible. Al final es lo que elegiría si
                viviera en libertad, recuerda que los perros son carnívoros y
                están diseñados por la naturaleza para alimentarse
                principalmente con carne. Por eso el Pack Dieta BARF de
                iniciación es perfecto para empezar este proceso.
              </p>

              <p>
                Pero Wild Balance no solo es para ellos, también está pensado
                para ti. Con nuestro <b>cómodo formato de hamburguesas</b> de
                50g te será muchísimo más fácil alimentar a tu gato con comida
                natural.
              </p>

              <p>
                <b>
                  <p>¿Qué son los Packs?</p>
                </b>
                Desde Wild Balance siempre hemos buscado que el mayor número de
                perros hagan la transición a la dieta BARF para que puedan
                disfrutar de todos sus beneficios. Pero para lograr eso nos
                dimos cuenta que cuanto más fácil os lo pongamos a vosotros,
                pues mucho mejor. Por eso creamos… ¡los packs de dieta BARF de
                Wild Balance! La gente busca la dieta BARF por muchos motivos y
                nosotros queriamos saber cuales eran para poder ofrecer packs
                más personalizados ¿Qué descubrimos? Pues que la gente busca la
                dieta BARF por variedad de razones como que su peludo pierda
                peso, querían una dieta variada, buscaban una dieta para su
                perro deportista y sobre todo se preguntaban cuál era la mejor
                forma de empezar la dieta BARF. Sabiendo esto decidimos crear la
                solución a cada uno de esos problemas:
                <b>
                  <p>• Pack Dieta BARF Iniciación Perros</p>
                  <p>• Pack Dieta BARF Degustación </p>
                  <p>• Pack Dieta BARF Perro Deportista</p>
                  <p>• Pack Dieta BARF Bajo en Calorias</p>
                  <p>• Pack Dieta BARF Pelaje Brutal</p>
                </b>
              </p>

              <p>
                Todos estos packs son una combinación de{" "}
                <b>nuestros menús BARF 100% naturales</b> y los hemos combinado
                de forma que te sea mucho más fácil comprar el pack que se
                adapte perfectamente a las necesidades de tu peludo.
              </p>

              <p>
                <b>
                  <p>Beneficios de los packs de dieta BARF</p>
                </b>
                Una de las principales ventajas del pack dieta BARF de
                iniciación de Wild Balance es que está compuesto por alimentos
                frescos y enteros, que pueden proporcionar una amplia variedad
                de nutrientes y ayudar a los gatos a mantener una buena salud.
                Los packs de dieta BARF también se basan en la idea de que los
                gatos tienen necesidades específicas y únicas, y que una dieta
                diseñada para ellos basada en la alimentación natural puede
                proporcionar una nutrición óptima.
              </p>

              <p>
                Si alimentas a tu perro con nuestros packs de dieta BARF le
                estás proporcionando todos los beneficios de la filosofía BARF,
                incluyendo una mejor digestión, una piel y un pelaje más
                saludables, un mayor nivel de energía, un sistema inmunológico
                más fuerte y una mayor longevidad. ¡Y encima están riquísimos!
              </p>

              <p>
                <b>
                  <p>Recomendaciones para ti</p>
                </b>
                Si estás considerando la dieta BARF para tu perro, es importante
                tener en cuenta que se debe proporcionar una dieta equilibrada y
                adecuada para las necesidades nutricionales de su perro. Esto
                significa que su perro debe recibir una variedad de alimentos
                que proporcionen proteínas, grasas, carbohidratos, vitaminas y
                minerales en cantidades adecuadas. Por eso, algunos de nuestros
                packs como el Pack BARF Degustación o el Pack BARF Bajo en
                Calorías se adaptan perfectamente a tus necesidades. Recuerda
                que nuestros menús van en packs congelados, por eso es muy
                importante llevarlos al congelador en cuanto te lleguen a casa.
                La mejor forma de guardar nuestro producto después de la compra
                seria: 1. Al recibir los menús BARF en casa, mételos al
                congelador y ordénalos de manera en que los menús que tengan más
                tiempo sean los primeros en utilizarse. 2. Calcula la cantidad
                de hamburguesitas diarias que necesita tu perro. 3. El día de
                antes descongela la cantidad de hamburguesitas diarias
                metiéndolas en la nevera para así no romper la cadena de frío.
                4. Si a tu perro no le gusta la comida fría, puedes calentarlas
                en el microondas 10-12 segundos para que se atempere.
              </p>

              <p>
                Y ante cualquier inconveniente que surja, nosotros siempre
                estaremos dispuestos a ayudarte. Si tienes dudas, escríbenos vía
                WhatsApp o llámanos al 670 319 204 ¡Te ayudaremos!
              </p>
            </div>
          </div>
        </p>

        <p>
          <button
            class="btn btn-primary faq-question"
            type="button"
            data-toggle="collapse"
            data-target="#collapse4"
            aria-expanded="false"
            aria-controls="collapse4"
          >
            Envíos y devoluciones
          </button>

          <div class="collapse" id="collapse4">
            <div class="card card-body">
              <p>
                <b>Quiénes somos</b>
              </p>

              <p>La dirección de nuestra web es: www.wildbalance.es</p>

              <p>Derecho de desistimiento y política de devoluciones</p>

              <p>
                <b>Devoluciones</b>
              </p>

              <p>
                El Usuario tiene derecho de desistimiento de la compra sin
                incurrir en penalización alguna, en un plazo de catorce (14)
                días naturales a contar desde la entrega del producto.
              </p>

              <p>
                No obstante, en virtud del art. art. 103 del Real Decreto
                Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el
                texto refundido de la Ley General para la Defensa de los
                Consumidores y Usuarios y otras leyes complementarias, serán
                excluidos del régimen general de desistimiento:
                <ul>
                  <li>
                    Productos congelados u otros productos perecederos que
                    puedan deteriorarse o caducar con rapidez
                  </li>
                  <li>
                    Productos precintados que no sean aptos para ser devueltos
                    por razones de protección de la salud o de higiene
                  </li>
                </ul>
              </p>

              <p>
                Las peticiones de devolución deberán efectuarse a través del
                Servicio de Atención del Cliente de Wild Balance mediante correo
                electrónico a hola@wild balance.es o al teléfono gratuito de
                contacto 670 319-204.
              </p>

              <p>
                <b>Compra</b>
              </p>

              <p>
                Para poder realizar compras en esta web no será necesario que el
                usuario se registre en la página, aunque tendrá la posibilidad
                de hacerlo para facilitar compras futuras, tener acceso a su
                historial o repetir compras recurrentes.
              </p>

              <p>
                Los envíos se realizarán en toda España y Portugal, exceptuando
                Islas Canarias, algunas Islas Baleares, Ceuta, Melilla e islas
                portuguesas.
              </p>

              <p>
                Todos los precios incluyen los impuestos legalmente aplicables
                (IVA).
              </p>

              <p>El pedido mínimo es de 5kg.</p>

              <p>Los gastos de envío serán de 5,99€ por cada envío.</p>

              <p>
                El precio final será comunicado previamente al cliente antes de
                formalizar la operación.
              </p>

              <p>
                El cliente se compromete a pagar en el momento que realiza el
                pedido.
              </p>

              <p>
                <b>Condiciones de envío</b>
              </p>

              <p>
                Nuestro pedido mínimo para favorecer la cadena de frío y las
                condiciones de congelación es de 5Kg.
                <p>
                  En el momento de la realización de tu pedido, se te asignará
                  un día de envío. El límite para cursar el pedido será a las
                  18h del viernes para los pedidos que se envían lunes, y las
                  18h del martes para los pedidos que se envían el miércoles.
                </p>
                <p>
                  Los pedidos llegarán a tu domicilio o al destino que nos
                  marques en horario de 8:00h de la mañana hasta las 14:00h,
                  pudiendo ser entregados posteriormente por motivos ajenos a
                  wildbalance.es.
                </p>
                <p>
                  Si es un problema para ti recibir el pedido en esa franja
                  horaria, siempre puedes enviarlo a la sede de la agencia de
                  transporte más cercana a tu domicilio. Consulta con nosotros
                  el contacto de la agencia más cercana.
                </p>
                <p>
                  Si eliges enviarlo a la sede, háznoslo saber indicándolo en
                  las observaciones de tu pedido. O bien puedes dejar el paquete
                  en el día elegido y durante el horario de entrega en un lugar
                  seguro (vecino, portero, tienda de al lado, etc.) Indícanoslo
                  siempre en observaciones.
                </p>
                <p>
                  En el caso de enviarlo a la sede más cercana, tu pedido estará
                  en el destino desde las 8:00 de la mañana. Te recomendamos
                  recogerlo durante la mañana.
                </p>
                <p>
                  Por favor, sé muy consciente de la fecha de envío, ya que
                  adquieres la responsabilidad de recepcionar el pedido dentro
                  de los horarios indicados. Al ser alimentación congelada no se
                  realizará la devolución del importe o reposición de la
                  mercancía si no se han seguido las indicaciones en esta guía.
                </p>
                <p>
                  Si el paquete presenta algún tipo de alteración,
                  descongelación o rotura es IMPRESCINDIBLE que se indique en el
                  albarán de entrega (o a la recepción) para poder proceder a
                  una reposición. En el caso de no que ser repuesto, se enviará
                  un vale descuento por el valor total del pedido para poder ser
                  usado en futuras compras.
                </p>
                <p>
                  Si el pedido es local y no se puede entregar a la hora
                  indicada, no respondiendo el cliente al teléfono, WhatsApp,
                  sms o email, la agencia de transporte devolverá el pedido a la
                  tienda Wild Balance más cercana. Dicha devolución correrá a
                  cargo del cliente que podrá ir a dicha tienda Wild Balance a
                  retirar el producto previo abono de los costes de envío (la
                  cantidad será la misma que los gastos de dicho envío o 3,99€
                  IVA INC en su defecto).
                </p>
                <p>
                  <b>
                    No elijas como día de entrega días festivos en tu localidad
                  </b>
                </p>
                <p>
                  La empresa de transporte no sirve en días de fiestas locales,
                  por favor, NO HAGAS TU PEDIDO si la fecha de entrega coincide
                  con un festivo local, autonómico o provincial, ya que la
                  comida llegará en este caso con un día de retraso. Llámanos en
                  caso de no estar segur@.
                </p>
                <p>
                  En casos aislados y debido a los procesos logísticos de las
                  empresas de mensajería, el pedido puede llegar un día después
                  al día elegido. Teniendo esto en cuenta, los envíos son
                  aislados en cajas EPS con gran cantidad de hielo seco para que
                  mantengan la cadena de frío al 100% hasta 48 horas.
                </p>
                <p>
                  En caso de que los tiempos de entrega se alarguen porque el
                  cliente no pueda recoger la entrega por cualquier motivo en el
                  día elegido, Wild Capital Partners SL no se puede hacer
                  responsable de la descongelación que los productos pudieran
                  sufrir y del posible impacto en la calidad de los mismos y no
                  podrá aceptar devoluciones en estos casos.
                </p>
                <p>
                  En caso de no poder realizar la primera entrega del producto
                  por error en dirección o ausencia del cliente en el domicilio
                  en la fecha elegida y en la franja horaria indicada
                  8:00-14:00h, se procederá a una segunda entrega en el mismo
                  día de forma automática o comunicando la agencia de transporte
                  con el cliente vía telefónica.
                </p>
                <p>
                  Ante la imposibilidad de entrega por no respuesta del cliente
                  y nueva ausencia, se procederá a la destrucción de la
                  mercancía a las 24h desde el segundo intento de entrega si el
                  cliente no pasa a recogerlo a la sede de la agencia encargada
                  de su localidad, perdiendo así el cliente la totalidad del
                  importe del pedido y los gastos de envío.
                </p>
                <p>
                  El plazo para ejecutar el pedido no superará los 30 días
                  naturales desde la recepción del pago por parte del CLIENTE.
                </p>
                <p>
                  <b>Gastos de envío</b>
                </p>
                <p>Condiciones generales de envíos:</p>
                <p>
                  Península
                  <p>
                    Carritos CON productos congelados: gastos de envío 5,99€.
                  </p>
                  <p>
                    Carritos SIN productos congelados: gastos de envío 1,99€.
                  </p>
                  <p>Pedido mínimo de productos congelados: 5kg</p>
                  <p></p>Día de envío: miércoles. Hora de corte para pedidos:
                  martes 23h.
                </p>
              </p>

              <p>
                Baleares
                <p>
                  Carritos CON productos congelados: gastos de envío 22,99€.
                </p>
                <p>Carritos SIN productos congelados: gastos de envío 4,99€.</p>
                <p>Pedido mínimo de productos congelados: 5kg</p>
                <p>
                  Día de envío: miércoles. Hora de corte para pedidos: martes
                  23h.
                </p>
              </p>

              <p>Valencia (sólo códigos postales aplicables)</p>

              <p>Cualquier tipo de producto: gastos de envío 3,99€.</p>
              <p>Pedido mínimo de productos congelados: 5kg</p>
              <p>
                Día de envío: lunes a viernes. Hora de corte para pedidos:
                martes 23h.
              </p>
              <p>Tramos de envíos: 10 a 12h, 12 a 14h y 17 a 19h.</p>

              <p>
                Canarias No disponible por el momento envío a domicilio en las
                Islas Canarias.{" "}
                <b>
                  Consulta la lista de tiendas distribuidoras de productos Wild
                  Balance en el Archipiélago.
                </b>
              </p>

              <p>
                Packaging Congelado: Cajas de EPS con hielo seco en transporte
                urgente. Los pedidos se entregarán al día siguiente de su día de
                envío, por la mañana antes de las 14h. En algunas ocasiones se
                podrá entregar a las 24h-36h por causas ajenas a Wild Balance.
                No congelado: cajas o sobres con transporte 24-48h.
              </p>
            </div>
          </div>
        </p>
      </section>

      <section
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <div className="textHeadingCarousel">
          <h3>Prepárate para ver los cambios</h3>
          <p>
            Sé uno de los valientes. Conoce a los perros y gatos que han
            cambiado su vida con WIld Balance
          </p>
        </div>

        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              class="d-block w-100"
              src="https://wildbalance.es/cdn/shop/files/coquito-wild-balance.jpg?v=1687353559&width=600"
              alt="First slide"
            ></img>
            <div class="carousel-item-pharagraf">
              <h4>
                Desde mis primeros días como Wild Balance y no puedo ser más
                feliz
              </h4>
              <p>
                Desde que mi mami me dio Wild Balance por primera vez me enamoré
                de esas hamburguesitas. Mis preferidas son las de pollo y las de
                conejo, me flipan. Ahora estoy empezando a probar las de pescado
                y me chupo las patitas.
              </p>
              <p>Coquito y Elena - @coquito.toy</p>
            </div>
          </div>

          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/donete-wild-balance.jpg?v=1687354264"
              alt="Second slide"
            ></img>
            <div class="carousel-item-pharagraf">
              <h4>
                La hora de la comida era un drama y mi pelaje no tenía brillo
              </h4>
              <p>
                Era muy mal comedor y no tenía apetito. Esta situación era muy
                preocupante para mis papás, habíamos probado de todo. Desde que
                como Wild Balance no hay día que no relama el plato. Mi pelo ha
                cambiado una barbaridad, tengo más cantidad y con un brillo
                envidiable.
              </p>
              <p>Judith y Donete - @iamdonete</p>
            </div>
          </div>

          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="https://wildbalance.es/cdn/shop/files/aslan-wild-balance.jpg?v=1687354263&width=400"
              alt="Third slide"
            ></img>
            <div class="carousel-item-pharagraf">
              <h4>
                Podía pasar días sin comer.. ¡No me gustaba nada el pienso!
              </h4>
              <p>
                El peor momento del día era la comida. Cuando veía el bol de
                pienso no quería ni olerlo. Un día paseando por Valencia mamá y
                yo descubrimos la tienda de Wild Balance. ¡Ha sido nuestra
                salvación! Ahora todos los días espero ansioso mi plato de
                hamburguesas.
              </p>
              <p>@noayaslan</p>
            </div>
          </div>

          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="https://wildbalance.es/cdn/shop/files/ak-wild-balance.jpg?v=1687354264&width=600"
              alt="Fourth slide"
            ></img>

            <div class="carousel-item-pharagraf">
              <h4>Mi dermatitis mejoró gracias a comer Wild Balance</h4>
              <p>
                Era probar un poco de pienso y toda mi piel se ponía rosa y
                llena de granitos. Esto le preocupaba bastante a mi mamá, le
                hablaron de Wild Balance y lo probamos. Desde que descubrí estos
                menús tan deliciosos ya no recuerdo lo desesperantes que eran
                esos picores. ¡Y adiós granitos!
              </p>
              <p>Mariana y Ak - @akdalmatian</p>
            </div>
          </div>

          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="https://wildbalance.es/cdn/shop/files/Boira-wild-balance.jpg?v=1687354264&width=600"
              alt="Fifth slide"
            ></img>

            <div class="carousel-item-pharagraf">
              <h4>Perdí mucho peso por culpa de unos problemas digestivos</h4>
              <p>
                Cada vez que comía vomitaba y acabé pesando 10,5kg. Probé muchos
                tipos de comidas pero nada me sentaba bien. Me dijeron que era
                alérgica al pollo. Con Wild Balance mis digestiones han mejorado
                de verdad y por fin he recuperado mi peso ideal. Y como veis,
                nada de alergias.
              </p>
              <p>Arantxa y Boira - @boira_bordercollie</p>
            </div>
          </div>

          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/margarita-wild-balance.jpg?v=1687354264"
              alt="Sixth slide"
            ></img>
            <div class="carousel-item-pharagraf">
              <h4>No me gustaba ningún pienso y dejé de comer durante días</h4>
              <p>
                Cuando llegué a casa de mis papás y vi aquel plato lleno de
                pienso… ¡eso no había quien se lo comiera! Dejé de comer y eso
                empezó a preocupar a mi mamá. ¡Menos mal que descubrimos Wild
                Balance! Desde el primer día dejé el plato reluciente y mis
                problemas de ansiedad mejoraron.
              </p>
              <p>Marta y Margarita - @margaritalasalchicha</p>
            </div>
          </div>
        </div>

        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
        </ol>
      </section>

      {/* <section className="sectionFixedCtaBuy">
        <div className="dataDiscount">
          <div>
            <span class="quantity">- 6%</span>
          </div>

          <div>
            <p>41,47€</p>
            <p>38,99€</p>
          </div>
        </div>
        <button className="btn calc">
          <a href="https://wildbalance.es/cart/45018279346477:1" target="_top">
            Comprar
          </a>
        </button>
      </section> */}

      {/* para desktop */}
      {/* <section
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">

            <article>
            <img
              class="d-block w-100"
              src="https://wildbalance.es/cdn/shop/files/coquito-wild-balance.jpg?v=1687353559&width=600"
              alt="First slide"
            ></img>
            <div class="carousel-item-pharagraf">
              <h4>
                Desde mis primeros días como Wild Balance y no puedo ser más
                feliz
              </h4>
              <p>
                Desde que mi mami me dio Wild Balance por primera vez me enamoré
                de esas hamburguesitas. Mis preferidas son las de pollo y las de
                conejo, me flipan. Ahora estoy empezando a probar las de pescado
                y me chupo las patitas.
              </p>
              <p>Coquito y Elena - @coquito.toy</p>
            </div>

            </article>

            <article>
            <img
              class="d-block w-100"
              src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/donete-wild-balance.jpg?v=1687354264"
              alt="Second slide"
            ></img>
            <div class="carousel-item-pharagraf">
              <h4>
                La hora de la comida era un drama y mi pelaje no tenía brillo
              </h4>
              <p>
                Era muy mal comedor y no tenía apetito. Esta situación era muy
                preocupante para mis papás, habíamos probado de todo. Desde que
                como Wild Balance no hay día que no relama el plato. Mi pelo ha
                cambiado una barbaridad, tengo más cantidad y con un brillo
                envidiable.
              </p>
              <p>Judith y Donete - @iamdonete</p>
            </div>

            </article>

            <article>
            <img
              class="d-block w-100"
              src="https://wildbalance.es/cdn/shop/files/aslan-wild-balance.jpg?v=1687354263&width=400"
              alt="Third slide"
            ></img>
            <div class="carousel-item-pharagraf">
              <h4>
                Podía pasar días sin comer.. ¡No me gustaba nada el pienso!
              </h4>
              <p>
                El peor momento del día era la comida. Cuando veía el bol de
                pienso no quería ni olerlo. Un día paseando por Valencia mamá y
                yo descubrimos la tienda de Wild Balance. ¡Ha sido nuestra
                salvación! Ahora todos los días espero ansioso mi plato de
                hamburguesas.
              </p>
              <p>@noayaslan</p>
            </div>

            </article>
            
          </div>

          <div class="carousel-item">

            <article>
            <img
              class="d-block w-100"
              src="https://wildbalance.es/cdn/shop/files/ak-wild-balance.jpg?v=1687354264&width=600"
              alt="Fourth slide"
            ></img>

            <div class="carousel-item-pharagraf">
              <h4>Mi dermatitis mejoró gracias a comer Wild Balance</h4>
              <p>
                Era probar un poco de pienso y toda mi piel se ponía rosa y
                llena de granitos. Esto le preocupaba bastante a mi mamá, le
                hablaron de Wild Balance y lo probamos. Desde que descubrí estos
                menús tan deliciosos ya no recuerdo lo desesperantes que eran
                esos picores. ¡Y adiós granitos!
              </p>
              <p>Mariana y Ak - @akdalmatian</p>
            </div>

            </article>
            

            <article>
            <img
              class="d-block w-100"
              src="https://wildbalance.es/cdn/shop/files/Boira-wild-balance.jpg?v=1687354264&width=600"
              alt="Fifth slide"
            ></img>

            <div class="carousel-item-pharagraf">
              <h4>Perdí mucho peso por culpa de unos problemas digestivos</h4>
              <p>
                Cada vez que comía vomitaba y acabé pesando 10,5kg. Probé muchos
                tipos de comidas pero nada me sentaba bien. Me dijeron que era
                alérgica al pollo. Con Wild Balance mis digestiones han mejorado
                de verdad y por fin he recuperado mi peso ideal. Y como veis,
                nada de alergias.
              </p>
              <p>Arantxa y Boira - @boira_bordercollie</p>
            </div>

            </article>
            

            <article>
            <img
              class="d-block w-100"
              src="https://cdn.shopify.com/s/files/1/0713/9898/1933/files/margarita-wild-balance.jpg?v=1687354264"
              alt="Sixth slide"
            ></img>
            <div class="carousel-item-pharagraf">
              <h4>No me gustaba ningún pienso y dejé de comer durante días</h4>
              <p>
                Cuando llegué a casa de mis papás y vi aquel plato lleno de
                pienso… ¡eso no había quien se lo comiera! Dejé de comer y eso
                empezó a preocupar a mi mamá. ¡Menos mal que descubrimos Wild
                Balance! Desde el primer día dejé el plato reluciente y mis
                problemas de ansiedad mejoraron.
              </p>
              <p>Marta y Margarita - @margaritalasalchicha</p>
            </div>

            </article>
            
          </div>

        </div>

        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol>
      </section> */}
    </section>
  );
}

export default ResultPageWizardDogs;
