import React, { useState, useEffect } from "react";

import { AiOutlineInfo } from "react-icons/ai";
import Tooltip from "./Tooltip";
import ResultQuiz from "../components/ResultQuiz/ResultQuiz";

function QuizWiBCats() {
  const urlBarfCats =
    "https://wildbalancemascotas.myshopify.com/collections/barf-gatos";

  const [petName, setPetName] = useState("");
  const [age, setAge] = useState("");
  const [weight, setWeight] = useState();
  const [activity, setActivity] = useState();
  const [showResult, setShowResult] = useState(false);
  const [foodType, setfoodType] = useState("");
  const [showTooltip, setShowTooltip] = useState();

  useEffect(() => {
    // Actualizar el componente ResultQuiz cuando cambie el valor del peso
    if (showResult) {
      setShowResult();
    }
  }, [weight]);

  const redirection =
    "https://wildbalancemascotas.myshopify.com/products/pack-iniciacion-barf-para-gatos";

  const tooltip = () => {
    if (showTooltip !== false) {
      setShowTooltip(false);
    } else {
      setShowTooltip(<Tooltip />);
    }
  };

  const awnserError = "Ups, intenta que el peso sea lo más aproximado posible";
  const gramsPerBurger = 50;
  const eurosPerBurger = 0.42;
  const gramsPerCooked = 120;

  let totalResult;
  let onclickfunctions;
  let calcAgain = () => {
    setPetName(false);
    setAge(false);
    setWeight(false);
    setActivity(false);
    setfoodType(false);
    setShowResult(false);
  };

  function validateWeight() {
    if (weight === "") {
      return null;
    } else if (age === "baby0" && (weight < 0.05 || weight > 40)) {
      return <p className="validationPar">{awnserError}</p>;
    } else if (age === "baby3" && (weight < 0.05 || weight > 40)) {
      return <p className="validationPar">{awnserError}</p>;
    } else if (age === "baby5" && (weight < 0.05 || weight > 40)) {
      return <p className="validationPar">{awnserError}</p>;
    } else if (age === "adult" && (weight < 0.03 || weight > 100)) {
      return <p className="validationPar">{awnserError}</p>;
    }
    return null;
  }

  function getGramsForCats(age, weight, activity) {
    weight = weight * 1000; // grams
    if (age !== "adult") {
      activity = "base";
    }

    
    let CAT_FACTORS_BURGERS = {
        baby0: {
          base: {
            min_posible_value: 50,
            max_posible_value: 400,
            values: [
              { min: 50, max: 499, value: 0.11 },
              { min: 500, max: 4000, value: 0.1 },
              { min: 4001, max: 40000, value: 0.1 },
            ],
          },
        },
        baby3: {
          base: {
            min_posible_value: 40,
            max_posible_value: 3200,
            values: [
              { min: 50, max: 499, value: 0.09 },
              { min: 500, max: 4000, value: 0.08 },
              { min: 4001, max: 40000, value: 0.08 },
            ],
          },
        },
        baby5: {
          base: {
            min_posible_value: 30,
            max_posible_value: 240,
            values: [
              { min: 50, max: 499, value: 0.07 },
              { min: 500, max: 4000, value: 0.06 },
              { min: 4001, max: 40000, value: 0.06 },
            ],
          },
        },
        adult: {
          senior: {
            min_posible_value: 30,
            max_posible_value: 300,
            values: [
              { min: 30, max: 999, value: 0.035 },
              { min: 1000, max: 10000, value: 0.03 },
              { min: 10001, max: 100000, value: 0.025 },
            ],
          },
          normal: {
            min_posible_value: 35,
            max_posible_value: 350,
            values: [
              { min: 30, max: 999, value: 0.04 },
              { min: 1000, max: 10000, value: 0.035 },
              { min: 10001, max: 100000, value: 0.025 },
            ],
          },
          high: {
            min_posible_value: 50,
            max_posible_value: 500,
            values: [
              { min: 30, max: 999, value: 0.08 },
              { min: 1000, max: 10000, value: 0.05 },
              { min: 10001, max: 100000, value: 0.04 },
            ],
          },
        },
      };


      let CAT_FACTORS_COOKED_FOOD = {
        baby0: {
          base: {
            min_posible_value: 50,
            max_posible_value: 400,
            values: [
              { min: 50, max: 499, value: 0.11 },
              { min: 500, max: 4000, value: 0.1 },
              { min: 4001, max: 40000, value: 0.1 },
            ],
          },
        },
        baby3: {
          base: {
            min_posible_value: 40,
            max_posible_value: 3200,
            values: [
              { min: 50, max: 499, value: 0.09 },
              { min: 500, max: 4000, value: 0.08 },
              { min: 4001, max: 40000, value: 0.08 },
            ],
          },
        },
        baby5: {
          base: {
            min_posible_value: 30,
            max_posible_value: 240,
            values: [
              { min: 50, max: 499, value: 0.07 },
              { min: 500, max: 4000, value: 0.06 },
              { min: 4001, max: 40000, value: 0.06 },
            ],
          },
        },
        adult: {
          senior: {
            min_posible_value: 30,
            max_posible_value: 300,
            values: [
              { min: 30, max: 999, value: 0.035 },
              { min: 1000, max: 10000, value: 0.03 },
              { min: 10001, max: 100000, value: 0.025 },
            ],
          },
          normal: {
            min_posible_value: 35,
            max_posible_value: 350,
            values: [
              { min: 30, max: 999, value: 0.045 },
              { min: 1000, max: 10000, value: 0.04 },
              { min: 10001, max: 100000, value: 0.035 },
            ],
          },
          high: {
            min_posible_value: 40,
            max_posible_value: 400,
            values: [
              { min: 30, max: 999, value: 0.065 },
              { min: 1000, max: 10000, value: 0.06 },
              { min: 10001, max: 100000, value: 0.055 },
            ],
          },
        },
      };
    

    let factors = foodType === "burgers" ? CAT_FACTORS_BURGERS : CAT_FACTORS_COOKED_FOOD;
    let gramsPerFood = foodType === "burgers" ? gramsPerBurger : gramsPerCooked;



    let factor_values = factors[age][activity]["values"];
    let factor_value = factor_values.find(
      (factor) => factor["min"] <= weight && factor["max"] >= weight
    );
    let factor = factor_value["value"];
    let grams = Math.round(weight * factor);
    let foodUnits = Math.round(grams / gramsPerFood);
    let daily_euros = foodUnits * eurosPerBurger;
    let month_euros = daily_euros * 31;
    return {
      grams: grams,
      foodUnits: foodUnits,
      foodType: foodType,
      dailyEuros: daily_euros.toFixed(2),
      monthEuros: month_euros.toFixed(2),
    };
  }
  return (
    <div className="containerPet">
      <section className="inputsPet">
        <input
          name="name"
          type="text"
          placeholder="Nombre de tu gatito"
          onChange={(e) => setPetName(e.target.value)}
          value={petName}
        />
        <select
          name="age"
          id="age"
          onChange={(e) => setAge(e.target.value)}
          value={age}
        >
          <option disabled={true} selected value="">Edad</option>
          <option value="baby0">Destete hasta 2 meses</option>
          <option value="baby3">3 meses a 4 meses</option>
          <option value="baby5">5 meses a 6 meses</option>
          <option value="adult">Adulto (más de 6 meses)</option>
        </select>

        <article className="weightCard">
          <input
            name="weight"
            type="number"
            placeholder="Peso"
            onChange={(e) => setWeight(e.target.value)}
            value={weight}
            min="0.01"
            max="40"
            required
          />
          <div className="answerErrorPa">{validateWeight()}</div>
        </article>

        <article className="foodTypeCard">
          <select
            name="foodType"
            id="foodType"
            onChange={(e) => setfoodType(e.target.value)}
            value={foodType}
          >
            <option disabled={true} selected value="">¿Qué quieres que coma?</option>
            <option value="burgers">Barf</option>
            <option value="cooked">Comida cocinada</option>
          </select>
        </article>


        <select
          name="activity"
          id="activity"
          placeholder="Nivel de actividad"
          onChange={(e) => setActivity(e.target.value)}
          defaultValue="Elige"
          value={activity}
        >
          <option selected>Actividad</option>
          <option value="senior">Castrados, sedentarios o abuelitos</option>
          <option value="normal">Actividad media y no castrados</option>
          <option value="high">
            Actividad alta, exterior, nerviosos y no castrados
          </option>
        </select>
      </section>
      {/* <section className="btnBox">
        <button
          className="btn calc"
          onClick={
            (onclickfunctions = () => {
              let totalResult = getGramsForCats(age, weight, activity);
              // reDirect();
              window.top.location.href =
                redirection +
                "?name=" +
                petName +
                "&burgers=" +
                totalResult["burgers"] +
                "&dailyEuros=" +
                totalResult["dailyEuros"] +
                "&monthEuros=" +
                totalResult["monthEuros"] +
                "&grams=" +
                totalResult["grams"];
            })
          }
        >
          Calcular
        </button>



        <button className="tooltip" onClick={(e) => tooltip()}>
          <AiOutlineInfo />
        </button>

        <section className="infoTooltip">{showTooltip}</section>
      </section> */}
      {showResult}
      {showResult ? (
        <button className="btn reset" onClick={calcAgain}>
          Resetear
        </button>
      ) : (
        <section className="btnBox">
          <button
            className="btn calc"
            onClick={
              (totalResult = () => {
                setShowResult(
                  <ResultQuiz
                    name={petName}
                    funct={getGramsForCats(age, weight, activity)}
                  />
                );
              })
            }
          >
            Calcular
          </button>

          <button className="tooltip" onClick={(e) => tooltip()}>
            <AiOutlineInfo />
          </button>

          <section className="infoTooltip">{showTooltip}</section>
        </section>
      )}{" "}
    </div>
  );
}

export default QuizWiBCats;
