import React from "react";
import burger from "../../burger.png";
import cooked from "../../cooked.png";
import { iframeResize } from "iframe-resizer";
import { iframeResizer } from "iframe-resizer";
import { Link, useHref } from "react-router-dom";


function ResultQuiz({ funct, name, redirection, foodType }) {
  let finalRedirection =
    redirection +
    "?name=" +
    name +
    "&foodUnits=" +
    funct["foodUnits"] +
    "&dailyEuros=" +
    funct["dailyEuros"] +
    "&monthEuros=" +
    funct["monthEuros"] +
    "&grams=" + 
    funct["grams"];

  console.error(finalRedirection);
  return (
    <div className="containerFinalResult">
      {funct === undefined ? undefined : (
        <section className="resultContainer">
          <h3>Para {name} te recomendamos:</h3>
          <div className="gramsDay">
            <div className="imgBurger">

            {foodType === 'burgers' ?  <img src={burger} alt="" /> :  <img src={cooked} alt="" />}

            </div>


            <p>X {funct["foodUnits"]}
                 {foodType === 'burgers' ? ' hamburguesitas' : ' paquetitos'}</p>
            <p className="equal">=</p>
            <p> {funct["grams"]} gr/día</p>
          </div>
          <div className="money">
            <div className="costDay">
              <p>Al día:</p>
              <p className="specialColor">
                desde <span>{funct["dailyEuros"]}€</span>
              </p>
            </div>

            <div className="costMonth">
              <p>Al mes:</p>

              <div className="sinceAndPrice">
                <p className="specialColor">
                  desde <span>{funct["monthEuros"]}€</span>
                </p>
              </div>
            </div>
          </div>
          <p className="infoDisclaimer">
            Recuerda que las cantidades recomendadas son orientativas. Cada
            animal tiene unas necesidades de alimentación diferentes, deberás
            aumentar o disminuir su ración si fuera necesario. Consúltanos si
            tienes cualquier duda.
          </p>

          <button className="btn calc">
            <a href={finalRedirection} target="_top">
              Descubre BARF
            </a>
          </button>
        </section>

        
      )}
    </div>
  );
}
export default ResultQuiz;
