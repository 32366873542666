import React from "react";
import { Link } from "react-router-dom";

function Bienvenida() {
  return (
    <div className="bienvenidaContainer">
      <h1>BIENDVENDIDX!!</h1>
      <p>
        Aquí te facilitamos la lista de los proyectos que albergamos y sus
        respectivas rutas para que puedas acceder a ellos sin problema
      </p>
      <section className="bienvenidaContainer__section">
        <ul>
          <li>
            <Link to="encuentra-tu-velca">- Encuentra tu Velca -</Link>
          </li>

          <li>
            <Link to="financing-simulator">- Simulador de Financiación -</Link>
          </li>

          <li>
            <Link to="quiz-Wild-Balance">- Quiz Wild Balance -</Link>
            <li>
              <Link to="wildDogs">- Quiz WiB/Dogs -</Link>
            </li>

            <li>
              <Link to="wildCats">- Quiz Wild/Cats -</Link>
            </li>
          </li>

          <li>
            <Link to="apiCallGenuins">- Api Call Genuins -</Link>
          </li>

          <li>
            <li>
              <Link to="resultPageWizardDogs">- Result Page Wizard Dog -</Link>
            </li>

            <li>
              <Link to="resultPageWizardCats">- Result Page Wizard Cat -</Link>
            </li>
          </li>
        </ul>
      </section>
      ResultPageWizardCats
      <h5>ESPERAMOS QUE LO DISFRUTES</h5>
    </div>
  );
}

export default Bienvenida;
