import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./main.scss";
import QuizWildBalance from "./QuizWildBalance/QuizWildBalance";
import Bienvenida from "./Bienvenida/Bienvenida";

// Calculadora Quiz WiB
import Dogs from "./QuizWildBalance/components/Dogs/Dogs";
import Cats from "./QuizWildBalance/components/Cats/Cats";
import QuizWiBDogs from "./QuizWildBalance/IndependentQuizs/QuizWiBDogs";
import QuizWiBCats from "./QuizWildBalance/IndependentQuizs/QuizWiBCats";

// Result Wizard Components WiB
import ResultPageWizardCats from "./ResultPageWizard/ResultPageWizardCats";
import ResultPageWizardDogs from "./ResultPageWizard/ResultPageWizardDogs";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Bienvenida />} />

          <Route path="quiz-Wild-Balance" element={<QuizWildBalance />} />
          <Route path="wildDogs" element={<QuizWiBDogs />} />
          <Route path="wildCats" element={<QuizWiBCats />} />

          <Route path="dogs" element={<Dogs />} />
          <Route path="cats" element={<Cats />} />

          <Route
            path="resultPageWizardDogs"
            element={<ResultPageWizardDogs />}
          />

          <Route
            path="resultPageWizardCats"
            element={<ResultPageWizardCats />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
