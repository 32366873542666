import React, { useState } from "react";
import Dogs from "./components/Dogs/Dogs";
import Cats from "./components/Cats/Cats";
import { PiDog } from "react-icons/pi";
import { PiCat } from "react-icons/pi";


function QuizWildBalance() {
  const [dog, setDog] = useState(<Dogs />);
  const [cat, setCat] = useState(false);

  const showDogs = () => {
    setCat(false);
    setDog(<Dogs />);
  };

  const showCats = () => {
    setDog(false);
    setCat(<Cats />);
  };

  return (
    <div className="containerQuizWiB">
      <p className="select">Selecciona:</p>
      <section className="selectionPet">
        <button
          className={dog === false ? "btn option" : "btn option active"}
          id="dogsBtn"
          onClick={(e) => showDogs()}
        >
          <PiDog className="iconPet"/>
          PERROS
        </button>
        <button
          className={cat === false ? "btn option" : "btn option active"}
          onClick={(e) => showCats()}
        >
          <PiCat className="iconPet"/>
          GATOS
        </button>
      </section>
      {dog} {cat}
    </div>
  );
}

export default QuizWildBalance;
