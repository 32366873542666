import React from "react";

function Tooltip() {
  return (
    <div className="tooltipContainer">
      <p>
        Sabemos que al principio asusta dar el paso, pero no te preocupes porque ¡no estás solo/a! Cuéntanos como es y te ayudaremos a crear el pack
        que mejor se adapta a sus necesidades, incluyendo todo lo que necesitas
        para iniciarte paso a paso en la alimentación natural BARF.
      </p>
      <p>
      Para que podamos hacer el cálculo correctamente, debes conocer el peso ideal de tu mascota (en kg). Ten en cuenta que las cantidades recomendadas son orientativas.
      </p>
    </div>
  );
}

export default Tooltip;
